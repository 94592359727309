@import './variables.scss';
@import './styles-base.scss';

:root {
  --primary-color: #{$primary};
  --primary-contrast-color: #{$primaryContrast};
  --accent-color: #{$accent};
  --accent-contrast-color: #{$accentContrast};
  --warn-color: #{$warn};
  --warn-contrast-color: #{$warnContrast};
}

.magico-85 {
  background-color: color-mix(
    in srgb,
    var(--mat-select-panel-background-color) 85%,
    rgba(255, 255, 255, 0.3)
  ) !important;
}

.row-flex-end {
  display: flex;
  justify-content: flex-end;
}

.row-flex-start {
  display: flex;
  justify-content: flex-start;
}

.row-space-between {
  display: flex;
  justify-content: space-between;
}

.row-space-around {
  display: flex;
  justify-content: space-around;
}

.column-center {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
