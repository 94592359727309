@use '@angular/material' as mat;

//
// Fuentes
//


// 
// Paletas de colores
// 

$primary-palette-colors:  (
    50 : #ffe0e0,
    100 : #ffb3b3,
    200 : #ff8080,
    300 : #fe4d4d,
    400 : #fe2626,
    500 : #fe0000,
    600 : #fe0000,
    700 : #fe0000,
    800 : #fe0000,
    900 : #fd0000,
    A100 : #ffffff,
    A200 : #fff1f1,
    A400 : #ffbebe,
    A700 : #ffa4a4,
    contrast: (
        50 : #000000,
        100 : #000000,
        200 : #000000,
        300 : #000000,
        400 : #ffffff,
        500 : #ffffff,
        600 : #ffffff,
        700 : #ffffff,
        800 : #ffffff,
        900 : #ffffff,
        A100 : #000000,
        A200 : #000000,
        A400 : #000000,
        A700 : #000000,
    )
    );
$accent-palette-colors: (
    50 : #fffbe0,
    100 : #fff5b3,
    200 : #ffee80,
    300 : #ffe74d,
    400 : #ffe226,
    500 : #ffdd00,
    600 : #ffd900,
    700 : #ffd400,
    800 : #ffcf00,
    900 : #ffc700,
    A100 : #ffffff,
    A200 : #fffcf2,
    A400 : #ffefbf,
    A700 : #ffe9a6,
    contrast: (
        50 : #000000,
        100 : #000000,
        200 : #000000,
        300 : #000000,
        400 : #000000,
        500 : #000000,
        600 : #000000,
        700 : #000000,
        800 : #000000,
        900 : #000000,
        A100 : #000000,
        A200 : #000000,
        A400 : #000000,
        A700 : #000000,
    )
    );
$warn-palette-colors: (
    50 : #f2e4e4,
    100 : #e0bbbc,
    200 : #cb8d8f,
    300 : #b65f62,
    400 : #a63d40,
    500 : #961b1e,
    600 : #8e181a,
    700 : #831416,
    800 : #791012,
    900 : #68080a,
    A100 : #ff999a,
    A200 : #ff6668,
    A400 : #ff3336,
    A700 : #ff1a1d,
    contrast: (
        50 : #000000,
        100 : #000000,
        200 : #000000,
        300 : #ffffff,
        400 : #ffffff,
        500 : #ffffff,
        600 : #ffffff,
        700 : #ffffff,
        800 : #ffffff,
        900 : #ffffff,
        A100 : #000000,
        A200 : #000000,
        A400 : #ffffff,
        A700 : #ffffff,
    )
    );


$primary-palette: mat.define-palette($primary-palette-colors);
$accent-palette: mat.define-palette($accent-palette-colors);
$warn-palette: mat.define-palette($warn-palette-colors);


$typography: mat.define-typography-config(
  $font-family: "Arial",
);


$theme: mat.define-light-theme(
  (
    color: (
      primary: $primary-palette,
      accent: $accent-palette,
      warn: $warn-palette,
    ),
    typography: $typography,
  )
);

// Colores

$background: #fdfffc;

$primary50: mat.get-color-from-palette($primary-palette, 50);
$primary100: mat.get-color-from-palette($primary-palette, 100);
$primary200: mat.get-color-from-palette($primary-palette, 200);
$primary300: mat.get-color-from-palette($primary-palette, 300);
$primary: mat.get-color-from-palette($primary-palette, 'default');
$primaryContrast: mat.get-color-from-palette($primary-palette, 'default-contrast');
$primary900: mat.get-color-from-palette($primary-palette, 'darker');

$accent: mat.get-color-from-palette($accent-palette, 'default');
$accentContrast: mat.get-color-from-palette($accent-palette, 'default-contrast');

$warn: mat.get-color-from-palette($warn-palette, 'default');
$warnContrast: mat.get-color-from-palette($warn-palette, 'default-contrast');

$negro-transparente: #0000002d;
$blanco-transparente: #ffffff80;
$azul: #18099c;
$celeste: #188ddb;
$rojo: #df0b0b;
$verde: #88d18a;
$amarillo: #ffc800; // alt: #FFC857
$gris: #aaaaaa;
$naranja: #ffad05;
$rosa: #e56399;
:root {
  --primary-color: #{$primary};
  --primary: #{$primary};
  --primary-50: #{$primary50};
  --primary-100: #{$primary100};
  --primary-200: #{$primary200};
  --primary-300: #{$primary300};
  --primary-500: #{$primary};
  --primary-900: #{$primary900};
  --primary-contrast-color: #{$primaryContrast};
  --accent-color: #{$accent};
  --accent-contrast-color: #{$accentContrast};
  --warn-color: #{$warn};
  --warn-contrast-color: #{$warnContrast};
  --negro-transparente: #{$negro-transparente};
  --blanco-transparente: #{$blanco-transparente};
  // Colores
  --azul: #{$azul};
  --celeste: #{$celeste};
  --rojo: #{$rojo};
  --verde: #{$verde};
  --amarillo: #{$amarillo};
  --gris: #{$gris};
  --naranja: #{$naranja};
  --rosa: #{$rosa};
}

